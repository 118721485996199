function qs(selector) {
  return document.querySelector(selector);
}
function intro() {
  const i = qs('.intro');
  const t = qs('.intro h1');
  const f = qs('.intro h2');
  const d = qs('.intro h3');
  const delay = 350;
  const delay2 = 250;
  const delay3 = 350;
  const delay4 = 145;
  const delay5 = 300;
  setTimeout(() => {
    t.classList.add('a');
    setTimeout(() => {
      t.classList.add('b');
    }, delay2);
    setTimeout(() => {
      f.classList.add('a');
      setTimeout(() => {
        f.classList.add('b');
      }, delay2);
      setTimeout(() => {
        d.classList.add('a');
        setTimeout(() => {
          d.classList.add('b');
          setTimeout(() => {
            i.classList.add('c');
            setTimeout(() => {
              i.classList.add('d');
              setTimeout(() => {
                i.classList.add('e');
              }, delay5);
            }, delay4);
          }, delay3);
        }, delay2);
      }, delay);
    }, delay);
  }, delay);
}
document.addEventListener('DOMContentLoaded', () => {
  setTimeout(intro, 500);
});
